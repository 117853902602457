'use strict';
(function () {

  class TurbineComponent {
    constructor($scope, Auth, usSpinnerService, Turbine, WorkOrder, $stateParams, $state, $uibModal) {
      this.Turbine = Turbine;
      this.WorkOrder = WorkOrder;
      this.$stateParams = $stateParams;
      this.usSpinnerService = usSpinnerService;
      this.isLoggedIn = Auth.isLoggedIn;
      this.$uibModal = $uibModal;
      this.turbine = {};
      this.components = [];
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.datePicker = { 
        date: { startDate: null, endDate: null },
        options: {
          ranges: {
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 14 Days': [moment().subtract(13, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()]
          }
        }
      };

      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.init();
        } else {
          $state.go('login');
        }
      });

      $scope.$on('updateTurbine', (event, data) => {
        //console.log('in update turbine event ');
        this.init();
      });
    }

    $onInit() {
      console.log('tunbine component init');
    }

    /* getRepairs(turbine) {
      this.WorkOrder.get({ id: 'turbine-repair', controller: turbine._id }).$promise
      .then(response=> {
        this.repairWOs = response.data;
        console.log('repairs: ', this.repairWOs);
      });
    } */

    init() {
      this.loading = true;
      this.Turbine.get({ id: this.$stateParams.id }).$promise
        .then(response => {
          if (response.data) {
            this.turbine = response.data;
            // console.log('turbine: ', this.turbine);
            // this.getRepairs(this.turbine);
          }
          this.loading = false;
          this.stopSpin('spinner-1');
        })
        .catch(err => {
          console.log(err);
          this.stopSpin('spinner-1');
          this.loading = false;
          this.turbine = null;
        })
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    openFullImage(url) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/view-full-image/viewFullImageModal.html',
        controller: 'ViewFullImageModalCtrl',
        controllerAs: 'vm',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          imageUrl: function () { return url }
        }
      });
      modalInstance.result.then(() => {
      });
    }

    resetdaterange() {
      this.datePicker = { date: { startDate: null, endDate: null } };
    }

  }

  angular.module('windmanagerApp')
    .component('turbine', {
      templateUrl: 'app/turbine/turbine.html',
      controller: TurbineComponent,
      controllerAs: 'tc'
    });
})();
